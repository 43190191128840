import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import GeneralButton from "../../components/generalButton/generalButton";
import OpenResultPageModal from "../../components/openResultPageModal/openResultPageModal";
import SEO from "../../components/SEO/SEO";
import Timer from "../../components/timer/timer";
import WritingContainer from "../../components/writingContainer/writingContainer";
import { config } from "../../config";
import { countWords, getSafeIdHeight, getSafeQueryHeight, showNotification } from "../../utils";

import "./writingPage.scss";

const generalTopics = require("../../topics/general.json");
const academicTopics = require("../../topics/academic.json");

export default function WritingPage() {
  const { type, task } = useParams();
  const navigate = useNavigate();
  const [taskType, setTaskType] = useState(task);

  const getRandomTopic = arr => {
    const randomTopic = Math.floor(Math.random() * arr.length);
    return randomTopic;
  };

  const [generalLetterTopic] = useState(generalTopics.letters[getRandomTopic(generalTopics.letters)]);
  const [generalEssayTopic] = useState(generalTopics.essay[getRandomTopic(generalTopics.essay)]);
  const [academicLetterTopic] = useState(academicTopics.letters[getRandomTopic(academicTopics.letters)]);
  const [academicEssayTopic] = useState(academicTopics.essay[getRandomTopic(academicTopics.essay)]);
  const [contentHeight, setContentHeight] = useState(null);
  const [inputLetter, setInputLetter] = useState("");
  const [inputEssay, setInputEssay] = useState("");
  const [openResultPageModal, setOpenResultPageModal] = useState({
    shown: false,
  });

  useEffect(() => {
    const TYPES = ["general", "academic"];
    const TASKS = ["letter", "essay"];

    if (!TYPES.includes(type)) {
      console.error("Invalid type");
      navigate("/404");
      return;
    }

    if (!TASKS.includes(task)) {
      console.error("Invalid task");
      navigate("/404");
      return;
    }
  }, [type, task]);

  const changeTaskType = taskType => {
    setTaskType(taskType);
    navigate(`/${type}/${taskType}`);
  };

  const goToResults = () => {
    navigate("/results");
  };

  const toggleOpenResultPageModal = () => {
    const shown = openResultPageModal.shown;
    setOpenResultPageModal({ shown: !shown });
  };

  const onTimeout = () => {
    try {
      window.sessionStorage.setItem(
        "results",
        JSON.stringify({
          type: type,
          letter: {
            ...(type === "general" ? generalLetterTopic : academicLetterTopic),
            result: inputLetter,
            words: countWords(inputLetter),
          },

          essay: {
            ...(type === "general" ? generalEssayTopic : academicEssayTopic),
            result: inputEssay,
            words: countWords(inputEssay),
          },
        }),
      );

      goToResults();
    } catch (err) {
      console.error(err);
      showNotification(
        "Something went wrong. We couldn't take you to the results page. You may just copy the text from the page and store it.",
        "error",
        "Your time is over",
      );
    }
  };

  const calculateHeight = () => {
    const documentHeight = window.innerHeight || 0;
    const contentHeight =
      // header's height
      getSafeIdHeight("header_container") +
      // footer's height
      getSafeIdHeight("footer") +
      // divider's height
      getSafeQueryHeight(".gradient-divider") +
      // padding (top & bottom) of the writing page
      parseInt(window.getComputedStyle(document.querySelector("#writing_page")).getPropertyValue("padding-top")) * 2 +
      getSafeIdHeight("timer") +
      getSafeQueryHeight(".task-navigation") +
      // timer's margin
      parseInt(window.getComputedStyle(document.querySelector("#timer")).getPropertyValue("margin-bottom")) +
      // navigation button margin
      parseInt(window.getComputedStyle(document.querySelector(".task-navigation")).getPropertyValue("margin-top"));

    setContentHeight(documentHeight - contentHeight);
  };

  useEffect(() => {
    if (window.innerWidth > config.MOBILE_WIDTH) {
      calculateHeight();
    }
  }, []);

  const getGeneralLetterTopic = () => {
    return (
      <div className="working-area" style={{ height: `${contentHeight}px` }}>
        <div className="task-container">
          <div className="letter-topic">
            <p>You should spend about 20 minutes on this task.</p>

            <div className="task">
              <p>{generalLetterTopic.description}</p>
              <p>{generalLetterTopic.task}</p>
              {generalLetterTopic.bullets.map((bullet, idx) => {
                return <p key={`letter-topic-${idx}`}>{`- ${bullet}`}</p>;
              })}
            </div>

            <p>Begin your letter as follows:</p>
            <p className="task">{generalLetterTopic.appeal}</p>
            <p>Write at least 150 words.</p>
            <p>You do NOT need to write any addresses.</p>
          </div>
        </div>
        <WritingContainer
          id={`text_${taskType}`}
          inputValue={inputLetter}
          setInputValue={setInputLetter}
          contentHeight={contentHeight}
        />
      </div>
    );
  };

  const getGeneralEssayTopic = () => {
    return (
      <div className="working-area" style={{ height: `${contentHeight}px` }}>
        <div className="task-container">
          <div className="essay-topic">
            <p>You should spend about 40 minutes on this task.</p>
            <p>Write about the following topic:</p>
            <div className="task">
              <p>{generalEssayTopic.task}</p>
              <p>{generalEssayTopic.question_1}</p>
              {generalEssayTopic.question_2 ? <p>{generalEssayTopic.question_2}</p> : null}
            </div>
            <p>
              Give a reason for your answer and include any relevant examples from your own knowledge or experience.
            </p>
            <p>Write at least 250 words.</p>
          </div>
        </div>
        <WritingContainer
          id={`text_${taskType}`}
          inputValue={inputEssay}
          setInputValue={setInputEssay}
          contentHeight={contentHeight}
        />
      </div>
    );
  };

  const getAcademicLetterTopic = () => {
    return (
      <div className="working-area" style={{ height: `${contentHeight}px` }}>
        <div className="task-container">
          <div className="letter-topic">
            <p>You should spend about 20 minutes on this task.</p>
            <p className="task">{academicLetterTopic.description}</p>
            <p>
              Summarise the information by selecting and reporting the main features, and make comparisons where
              relevant.
            </p>
            <p>Write at least 150 words.</p>
            <img
              className="diagram"
              src={`${process.env.PUBLIC_URL}/images/letter-graphs/${academicLetterTopic.image}`}
              alt="Writing task 1"
            />
          </div>
        </div>
        <WritingContainer
          id={`text_${taskType}`}
          inputValue={inputLetter}
          setInputValue={setInputLetter}
          contentHeight={contentHeight}
        />
      </div>
    );
  };

  const getAcademicEssayTopic = () => {
    return (
      <div className="working-area" style={{ height: `${contentHeight}px` }}>
        <div className="task-container">
          <div className="essay-topic">
            <p>You should spend about 40 minutes on this task.</p>
            <p>Write about the following topic:</p>
            <div className="task">
              <p>{academicEssayTopic.task}</p>
              <p>{academicEssayTopic.question_1}</p>
              {academicEssayTopic.question_2 ? <p>{academicEssayTopic.question_2}</p> : null}
            </div>
            <p>Give reasons for your answer and include any relevant examples from your own knowledge or experience.</p>
            <p>Write at least 250 words.</p>
          </div>
        </div>
        <WritingContainer
          id={`text_${taskType}`}
          inputValue={inputEssay}
          setInputValue={setInputEssay}
          contentHeight={contentHeight}
        />
      </div>
    );
  };

  return (
    <div id="writing_page">
      <SEO
        title="Real-time exam practice for IELTS writing"
        description="Experience authentic exam conditions and enhance your writing skills with real-time exam practice"
      />

      <Timer onTimeout={onTimeout} minutes={process.env.REACT_APP_TIMEOUT_MINUTES} />
      {type === "academic" ? (
        <>{taskType === "letter" ? getAcademicLetterTopic() : getAcademicEssayTopic()}</>
      ) : (
        <>{taskType === "letter" ? getGeneralLetterTopic() : getGeneralEssayTopic()}</>
      )}

      <div className="task-navigation">
        <GeneralButton text="Part 1" action={() => changeTaskType("letter")} isActive={taskType === "letter"} />
        <GeneralButton text="Part 2" action={() => changeTaskType("essay")} isActive={taskType === "essay"} />
        <GeneralButton text="Finish" action={toggleOpenResultPageModal} className="primary" />
      </div>

      {openResultPageModal.shown && (
        <OpenResultPageModal
          displayModal={openResultPageModal.shown}
          closeModal={toggleOpenResultPageModal}
          goToResultPage={onTimeout}
        />
      )}
    </div>
  );
}
