import React, { useEffect, useState } from "react";

import { config } from "../../config";
import { countWords } from "../../utils";

import "./writingContainer.scss";

const DEFAULT_MOBILE_HEIGHT = 300;

export default function WritingContainer({ id, inputValue, setInputValue, contentHeight }) {
  const [wordNumber, setWordNumber] = useState(0);
  const [textAreaHeight, setTextAreaHeight] = useState(0);

  useEffect(() => {
    calculateTextAreaHeight();
  }, [contentHeight]);

  useEffect(() => {
    setWordNumber(countWords(inputValue));
  }, [id, inputValue]);

  const calculateTextAreaHeight = () => {
    if (contentHeight === 0) {
      return;
    }

    if (window.innerWidth > config.MOBILE_WIDTH) {
      const wordCountHeight = document.getElementById("word_count").offsetHeight;
      setTextAreaHeight(contentHeight - wordCountHeight);
    } else {
      setTextAreaHeight(DEFAULT_MOBILE_HEIGHT);
    }
  };

  return (
    <div className="writing-container">
      <textarea
        id={id}
        onChange={e => setInputValue(e.target.value)}
        autoFocus
        autoCorrect="off"
        autoComplete="off"
        spellCheck="false"
        value={inputValue}
        style={{ height: `${textAreaHeight}px` }}
      ></textarea>
      <div id="word_count">Word count: {wordNumber}</div>
    </div>
  );
}
