import React from "react";

import SEO from "../../../components/SEO/SEO";
import StartWritingButtons from "../../../components/startWritingButtons/startWritingButtons";

import "./articlesPage.scss";

const articlesList = require("../../../topics/articles.json");

export default function Articles() {
  const renderArticlesList = () => {
    return (
      <div className="topics-list">
        {articlesList.articles.map((article, idx) => {
          return (
            <div className="topic" key={idx}>
              <a href={article.url}>
                <div className="topic-title">{article.title}</div>
                <div className="topic-description">{article.description}</div>
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div id="articles_page">
      <SEO
        title="Useful materials for IELTS writing preparations"
        description="Essential and must-read articles for IELTS writing preparations"
      />

      <div className="articles-header">
        <h1>Learn and Discover</h1>
        <div className="header-divider"></div>
        <div className="header-text">
          <p>Essential and must-read articles</p>
          <p>for IELTS writing preparations</p>
        </div>
      </div>
      {renderArticlesList()}

      <StartWritingButtons />
    </div>
  );
}
