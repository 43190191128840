import React from "react";

import SEO from "../../components/SEO/SEO";
import StartWritingButtons from "../../components/startWritingButtons/startWritingButtons";
import thinkingPeople from "../../images/people_thinking.jpg";

import "./infoPage.scss";

export default function InfoPage() {
  return (
    <div id="info_page">
      <SEO
        title="So how does Writing Simulator work?"
        description="Experience an exam-like environment with timers, word counters, and training topics"
      />

      <div className="info-header">
        <h1>So how does Writing Simulator work?</h1>
      </div>

      <div className="main-text">
        It is widely acknowledged that one of the most challenging aspects of the exam is writing. During preparation,
        there is frequently a lack of organization and motivation to properly prepare oneself for the upcoming
        examination. The Writing Simulator provides an immersive exam-like experience, featuring timers and word
        counters, along with a curated selection of training topics. Depending on the chosen exam type, academics
        include tasks such as graph or diagram analysis and essay writing, while generals feature letter and essay
        prompts. All topics are sourced from official IELTS preparation textbooks.
      </div>
      <div className="info-img">
        <div className="block-divider"></div>
        <img className="thinking-people" alt="Thinking people" src={thinkingPeople} />
        <div className="block-divider"></div>
      </div>

      {/* TODO: Think about illustrations, gifs, video */}
      <div className="info-text">
        <p>
          Once your training session begins, the countdown timer initiates simultaneously. You are allotted a total of
          60 minutes to complete your writing examination, with the remaining time prominently displayed at the top of
          the page for your reference. As the exam nears its conclusion, a highlighted notification will appear ten
          minutes prior, signaling the need to wrap up and carefully review your work. It is highly recommended to
          allocate your time wisely, dedicating approximately 20 minutes to the initial section and 40 minutes to the
          essay portion. This strategic approach underscores the essence of training, enabling you to master the art of
          resource allocation and skill utilization effectively.
        </p>
        <p>
          During the examination, you have the flexibility to switch between tasks using the "Part 1" and "Part 2"
          buttons located at the bottom of the screen. The highlighted button indicates the task you are presently
          engaged in. Do not worry, when transitioning between sections, any data you have inputted remains untouched.
        </p>
        <p>
          The layout of the page ensures that functionality is constantly visible. Therefore, if a graph or diagram
          becomes invisible by a substantial amount of data, simply scroll down to view it.
        </p>
        <p> Similar to the actual exam, there is no autocomplete feature or spell-checking capability available.</p>
        <p>
          Once the time expires, it will no longer be possible to continue working on the exam. However, you have the
          option to save your written content. This allows you to revisit, analyze, and review your work at your
          convenience during your free time.
        </p>
      </div>

      <StartWritingButtons />
    </div>
  );
}
