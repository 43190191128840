import React, { useState } from "react";

import arrow from "../../images/arrow.png";
import GeneralButton from "../generalButton/generalButton";
import StartWritingModal from "../startWritingModal/startWritingModal";

import "./startWritingButtons.scss";

export default function StartWritingButtons() {
  const [startWritingModal, setStartWritingModal] = useState({
    shown: false,
    type: "",
  });

  const toggleStartWritingModal = type => {
    const shown = startWritingModal.shown;
    setStartWritingModal({ shown: !shown, type });
  };

  return (
    <div id="action_buttons">
      <div className="action-text">
        <img src={arrow} alt="Arrow down" />
        <p>Start your practice now</p>
        <img src={arrow} alt="Arrow down" />
      </div>

      <div className="start-writing-buttons">
        <GeneralButton isActive action={() => toggleStartWritingModal("general")} text="General Training" />
        <GeneralButton isActive action={() => toggleStartWritingModal("academic")} text="Academic Training" />
      </div>

      {startWritingModal.shown && (
        <StartWritingModal
          displayModal={startWritingModal.shown}
          closeModal={toggleStartWritingModal}
          type={startWritingModal.type}
        />
      )}
    </div>
  );
}
