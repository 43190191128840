import React, { useEffect, useState } from "react";

import "./timer.scss";

const stepMs = 1000;
const minuteMs = 60000;

export default function Timer({ onTimeout, minutes }) {
  const [milliseconds, setMilliseconds] = useState(minutes * 60 * 1000);
  const [timerColor, setTimerColor] = useState("");
  const [time, setTime] = useState(minutes);

  const getTimerColor = () => {
    if (milliseconds < 10 * 60 * 1000 && timerColor !== "#ac3b61") {
      setTimerColor("#ac3b61");
    }
  };

  const getTime = () => {
    if (milliseconds >= minuteMs) {
      setTime(Math.ceil(milliseconds / 1000 / 60));
    } else {
      setTime(Math.ceil(milliseconds) / 1000);
    }
  };

  useEffect(() => {
    getTimerColor();
    getTime();
    const interval = setTimeout(() => {
      if (milliseconds > 0) {
        setMilliseconds(milliseconds - stepMs);
      }
    }, stepMs);

    if (milliseconds === 0) {
      onTimeout();
    }

    return () => clearTimeout(interval);
  }, [milliseconds]);

  return (
    <div id="timer">
      <div className="minutes" style={{ color: timerColor }} data-testid="timerValue">
        {time}
      </div>
      <div data-testid="timerMeasure">{milliseconds < minuteMs ? "seconds" : "minutes"} left</div>
    </div>
  );
}
