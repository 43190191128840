import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";
import letter_1 from "../../../images/informal_letter_1.png";
import letter_2 from "../../../images/informal_letter_2.png";

const recommendations = [
  "Appropriate greeting: All informal letters begin with “Dear…,” followed by the first name. Do not include the last name or courtesy titles.",
  "Introduction: In informal letters, it is better to start with general phrases, such as apologizing for not writing for a long time or being out of touch.",
  "Body paragraphs: Three paragraphs, addressing the bullet points in the task, trying to keep a friendly and informal tone.",
  "Contractions: An informal letter is the only place in the IELTS writing exam where you are not only allowed but required to use contractions.",
  "Closing remark: Write a few sentences to arrange a meeting or offer friendly advice.",
  "Signing: For informal letters, the best option would be “Best wishes/Love” followed by your first name.",
];

export default function InformalLetter() {
  const renderRecommendations = () => {
    return (
      <ul>
        {recommendations.map((rec, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {rec}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="informal_letter" className="article">
      <SEO
        title="Is it that easy to write an informal letter?"
        description="Learn how to identify and structure a informal letter for the IELTS exam, using informal vocabulary, phrasal verbs and idioms to effectively express your understanding of the task."
      />
      <h1>Is it that easy to write an informal letter?</h1>
      <div className="article-body">
        <div>
          Yes and no. Many people find this type of letter straightforward since its structure is close to everyday life
          and conversation. For most, this is indeed the case. However, some individuals hardly understand how it should
          be written in a format suitable for an exam. To learn how to identify the type of letter required by the task
          <Link to="/articles/letter-types" className="link">
            read here
          </Link>
          and the steps you should follow you can find
          <Link to="/articles/letter-basic-concepts" className="link">
            in this article.
          </Link>
        </div>
        <div>
          The main aim of informal letters is to demonstrate your understanding of the given questions in the task and
          express your answer informally. This should include the use of phrasal verbs, informal vocabulary, and idioms
          while avoiding highly sufficient formal expressions.
        </div>
        <div>Let's go through some vital factors in informal letters:</div>
        {renderRecommendations()}
        <div>
          Below, you can see examples of informal letters. These are not templates or perfect examples of informal
          letters, but they will give you an idea of what it looks like.
        </div>
        <img className="example" src={letter_1} alt="Informal letter" />
        <img className="example" src={letter_2} alt="informal letter" />
        <div>
          As you can see, both letters have the required structure, with clear spacing between the paragraphs, and cover
          all the bullet points from the tasks.
        </div>
        <div>You may find additional useful materials in the articles below.</div>
      </div>
    </article>
  );
}
