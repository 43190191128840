import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Link, Outlet } from "react-router-dom";

import Footer from "./components/footer/footer";
import MainMenu from "./components/mainMenu/mainMenu";
import Notification from "./components/notification/notification";
import logo from "./images/logo.png";

export default function Layout() {
  return (
    <HelmetProvider>
      <div className="layout">
        <Notification />
        <div id="header_container">
          <Link id="logo" to="/">
            <img alt="Logo" src={logo} />
          </Link>

          <MainMenu />
        </div>

        <div className="gradient-divider"></div>

        <main>{<Outlet />}</main>
        <Footer />
      </div>
    </HelmetProvider>
  );
}
