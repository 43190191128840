import React from "react";

import "./footer.scss";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-text">
        Free IELTS Writing Simulator that will simplify your preparation, at least a little...
      </div>
      <div className="external-link">
        All images provided by
        <a href="https://www.vecteezy.com" target="_blank" rel="noreferrer">
          vecteezy.com
        </a>
      </div>
    </footer>
  );
}
