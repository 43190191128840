import React from "react";

import "./articlesList.scss";

const articlesList = require("../../topics/articles.json");

export default function ArticlesList({ currentURL }) {
  const renderArticlesList = () => {
    let counter = 0;

    return (
      <div className="articles-list">
        {articlesList.articles.slice(0, 6).map((article, idx) => {
          if (counter >= 5) return "";
          if (article.url !== currentURL) {
            counter++;
            return (
              <div className="topic" key={idx}>
                <a href={article.url}>
                  <div className="topic-title">{article.title}</div>
                </a>
              </div>
            );
          }
          return "";
        })}
      </div>
    );
  };

  return (
    <div id="articles_list">
      <h2>You may also be interested:</h2>
      {renderArticlesList()}
    </div>
  );
}
