import React from "react";

import SEO from "../../components/SEO/SEO";
import StartWritingButtons from "../../components/startWritingButtons/startWritingButtons";
import happyPeople from "../../images/happy_people.jpg";

import "./aboutUs.scss";

export default function AboutUs() {
  return (
    <div id="about_us">
      <SEO
        title="Streamline your IELTS Preparation with the Writing Simulator"
        description="Prepare effectively for the written part of the IELTS exam by organizing materials and practicing in real-time with the Writing Simulator"
      />

      <div className="about-header">
        <div className="header-text">
          <p>Has Everything</p>
          <p>You Need</p>
        </div>
        <div className="header-divider"></div>
        <h1>IELTS Writing Simulator</h1>
      </div>
      <div className="main-img">
        <div className="block-divider"></div>
        <img className="people-img" alt="Happy people" src={happyPeople} />
        <div className="block-divider"></div>
      </div>
      <div className="main-text">
        <p>
          Properly arranging the requisite materials and resources is crucial when preparing for the written part of the
          exam. Furthermore, practicing in real-time is particularly important to ensure meeting time limits.
        </p>
        <p>
          The Writing Simulator is intended to support your exam preparations by simplifying the process of selecting
          topics, managing timing, and tracking word counts.
        </p>
        <p>
          It offers all the necessary tools to save your time, at least slightly ease your stress before an important
          event, and make you happier!
        </p>
      </div>
      <StartWritingButtons />
    </div>
  );
}
