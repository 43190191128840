import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";

const conceptsList = [
  "Just like essays, it is crucial to track word count. Aim to meet the minimum requirement of 150 words while also avoiding exceeding 200 words. Ideally, try to commit to 180-190 words. This demonstrates to the examiner that you comprehensively understand the task and can deliver your solution within the recommended word limit.",
  "Visually, your letter should appear tidy with spacing between paragraphs.",
  "While each task is presented with three bullet points, it is essential to address the posed question meticulously, ensuring each is fully revealed. Ideally, allocate a separate paragraph for each bullet point, aiming for the sameness in their size.",
  "It is highly important to read the assignments attentively and avoid introducing any unnecessary information.",
  "Begin the greeting exactly as specified in the task. If the task says: “Dear…”, your letter must start with the same greeting.",
  "After the greeting you should clearly state the purpose of your letter, sticking to the appropriate format for formal, semi-formal, or informal letters.",
  "Conclude the letter by expressing gratitude, suggesting a meeting, or indicating your expectation of a response, depending on the type and subject of the letter.",
  "Ensure that both the greeting and signature align with the type of letter and maintain the appropriate tone throughout.",
];

export default function LetterBasicConcepts() {
  const renderListOfConcepts = () => {
    return (
      <ul>
        {conceptsList.map((concept, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {concept}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="letter_basic_concept" className="article">
      <SEO
        title="The role of Letters in Assessment"
        description="Explore the underestimated importance of letters in the IELTS exam"
      />
      <h1>The role of Letters in Assessment</h1>
      <div className="article-body">
        <div>
          The importance of letters during the IELTS writing test is often underestimated by many, as it constitutes
          only a third of the overall grade with the main emphasis placed on the essay. However, it is essential to look
          at it from another perspective. With one-third of the assessment attributed to the letter, it is worth
          considerable attention! There have been numerous cases where students received lower grades than expected,
          primarily due to insufficient time and attention devoted to letter writing.
        </div>
        {renderListOfConcepts()}
        <div>
          You also can learn how to determine the needed type of the letter to use exam from the
          <Link to="/articles/letter-types" className="link">
            following article.
          </Link>
        </div>
      </div>
    </article>
  );
}
