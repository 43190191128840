import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";
import letter from "../../../images/semi-formal_letter.png";

const recommendations = [
  "Appropriate greeting: A semi-formal letter always begins with “Dear Mr/Ms”, followed by the last name. Do not include the first name in the greeting.",
  "Introduction: A few sentences explaining the reason for your letter.",
  "Body paragraphs: Three paragraphs, addressing the bullet points in the task.",
  "Closing remarks: Write two or three sentences that are appropriate to the task.",
  "Appropriate signing: For semi-formal letters the best to use is “Yours sincerely,” followed by your full name.",
  "Even if the tone is close to friendly, do not use any contractions. They have a place just in informal letters.",
];

export default function SemiFormalLetter() {
  const renderRecommendations = () => {
    return (
      <ul>
        {recommendations.map((rec, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {rec}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="semiformal_letter" className="article">
      <SEO
        title="Subtleties of the most controversial semi-formal letter"
        description="Learn how to identify and structure a semi-formal letter for the IELTS exam."
      />
      <h1>Subtleties of the most controversial semi-formal letter</h1>
      <div className="article-body">
        <div>
          From the article on
          <Link to="/articles/letter-types" className="link">
            determining the type of letter,
          </Link>
          we have already learned that semi-formal letters always begin with “Dear Mr/Ms …,”. However, unlike other
          types, simply using formal/informal vocabulary and tone is not enough.
        </div>
        <div>
          The key to mastering this type of letter is understanding that, while the assignment indicates it is a
          semi-formal letter, the tone must still be adapted to whom you are writing. If you are writing to a senior
          manager from work, the style should be more restrained and formal, but not too exaggerated, since it is still
          a semi-formal type. Conversely, if you are writing to a neighbour, the tone may be more friendly, though not
          as casual as in an informal letter.
        </div>
        <div>
          Essentially, a semi-formal letter is a balance between formal and informal styles, with the tone varying on
          the purpose and recipient. What general steps you should follow to write a letter you may
          <Link to="/articles/letter-basic-concepts" className="link">
            read here.
          </Link>
        </div>
        <div>Nevertheless, there are key points to keep in mind for semi-formal letters:</div>
        {renderRecommendations()}
        <div>
          Below, you can see examples of semi-formal letters. This is not a template or perfect example of semi-formal
          letters, but it might give you an idea of what it looks like.
        </div>
        <img className="example" src={letter} alt="Semi-formal letter" />
        <div>
          Please note that even though at first glance it seems that this is a formal letter, the hint in the task “Dear
          Mr/Ms” indicates that this is a semi-formal letter. Accordingly, we should start with the same greeting
          followed by a surname, and finish with an appropriate signature. The tone of the letter is close to formal
          since this is a letter to a person almost unknown to us.
        </div>
        <div>
          It is worth highlighting that visually all paragraphs have a visible space between each other. The first
          paragraph contains an introduction and the first bullet point, which is allowed if it looks natural. But you
          should not do this with the last paragraph and closing remarks, it is better to write them separately.
        </div>
        <div>You may find additional useful materials in the articles below.</div>
      </div>
    </article>
  );
}
