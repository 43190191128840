import jspdf from "jspdf";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";

import GeneralButton from "../../components/generalButton/generalButton";
import StartWritingModal from "../../components/startWritingModal/startWritingModal";
import logo from "../../images/logo.png";
import { showNotification } from "../../utils";

import "./results.scss";

export default function Results() {
  const [startWritingModal, setStartWritingModal] = useState({
    shown: false,
    type: "",
  });

  let results;

  try {
    results = JSON.parse(window.sessionStorage.getItem("results"));

    if (!results) {
      throw new Error("error");
    }
  } catch (err) {
    console.error(err);
    return <Navigate to="/404" />;
  }

  const toggleStartWritingModal = type => {
    const shown = startWritingModal.shown;
    setStartWritingModal({ shown: !shown, type });
  };

  const downloadPDF = () => {
    try {
      const doc = new jspdf({ putOnlyUsedFonts: true });
      const elementHTML = document.querySelector(".results-content");
      const date = new Date().toLocaleString();

      doc.html(elementHTML, {
        callback: function (doc) {
          doc.save(`${results.type}_writing_${date}.pdf`);
        },
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        width: 190, //target width in the PDF document
        windowWidth: 675, //window width in CSS pixels
        fontFaces: [{ family: "Times-Roman", style: "normal" }],
      });
    } catch (err) {
      console.error(err);
      showNotification(
        "Something went wrong. Try again or just copy the text from the page and store it.",
        "error",
        "Could not download results",
      );
    }
  };

  const renderLetterResults = () => {
    return (
      <div className="letter-results" style={{ marginBottom: 20 }}>
        {results.type === "general" ? (
          <div className="task">
            <p>{results.letter.description}</p>
            <p>{results.letter.task}</p>
            {results.letter.bullets.map((bullet, idx) => {
              return <p key={`letter-topic-${idx}`}>{`- ${bullet}`}</p>;
            })}
            <p>Begin your letter as follows:</p>
            <p>{results.letter.appeal}</p>
          </div>
        ) : (
          <div className="task">
            <p style={{ marginBottom: 5 }}>{results.letter.description}</p>
            <img
              className="diagram"
              src={`${process.env.PUBLIC_URL}/images/letter-graphs/${results.letter.image}`}
              alt="Writing task 1"
            />
          </div>
        )}
        <div className="result" style={{ marginTop: 15, marginBottom: 15 }}>
          {results.letter.result.split("\n").map((par, idx) => {
            return <p key={idx}>{par}</p>;
          })}
        </div>
        <div className="word-count">Word count: {results.letter.words}</div>
      </div>
    );
  };

  const renderEssayResults = () => {
    return (
      <div className="essay-results">
        <div className="task">
          <p>{results.essay.task}</p>
          <p>{results.essay.question_1}</p>
          {results.essay.question_2 ? <p>{results.essay.question_2}</p> : null}
        </div>
        <div className="result" style={{ marginTop: 15, marginBottom: 15 }}>
          {results.essay.result.split("\n").map((par, idx) => {
            return <p key={idx}>{par}</p>;
          })}
        </div>
        <div className="word-count">Word count: {results.essay.words}</div>
      </div>
    );
  };

  const renderResults = () => {
    return (
      <div className="results-content" style={{ fontSize: 16, textAlign: "justify" }}>
        <img className="logo" style={{ marginLeft: -10 }} alt="Logo" src={logo} width={100} />
        <h2>Part 1</h2>
        {renderLetterResults()}
        <h2>Part 2</h2>
        {renderEssayResults()}
      </div>
    );
  };

  return (
    <div id="results_page">
      <div className="main-content">
        <h1>Congratulations, your exam is over!</h1>
        <p className="secondary-text">You can review and download your results below.</p>
        {renderResults()}
      </div>
      <div className="action-buttons">
        <GeneralButton text="Download" action={downloadPDF} isActive />
        <GeneralButton
          text="Start new Attempt"
          action={() => toggleStartWritingModal(results.type)}
          className="primary"
        />
      </div>

      {startWritingModal.shown && (
        <StartWritingModal
          displayModal={startWritingModal.shown}
          closeModal={() => toggleStartWritingModal(results.type)}
          type={startWritingModal.type}
        />
      )}
    </div>
  );
}
