import React from "react";
import { Link } from "react-router-dom";

import home from "../../icons/home.png";
import info from "../../icons/info.png";

import "./mainMenu.scss";

const icons = [
  { path: "/info", alt: "Info", src: info },
  { path: "/", alt: "Home", src: home },
];

export default function MainMenu() {
  const renderLinks = () => {
    return (
      <>
        <Link to={"/about-us"}>Why Writing Simulator?</Link>
        <Link to={"/articles"}>Learn and Discover</Link>
      </>
    );
  };

  const renderMenu = () => {
    return icons.map((icon, idx) => {
      return (
        <Link to={icon.path} key={idx}>
          <img className="nav-icon" alt={icon.alt} src={icon.src} />
        </Link>
      );
    });
  };

  return (
    <div id="header-navigation">
      <div className="info-links">{renderLinks()}</div>
      <div className="menu-icon">{renderMenu()}</div>
    </div>
  );
}
