import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";

const typesOfLetter = [
  "If the task says “Dear …” (often followed by three dots or a specific name provided), it is undoubtedly an informal letter. In this case, address the recipient by their name, without adding surnames or contractions. Even if the letter is intended for your boss or hotel staff, and the task says  “Dear …”, you should write “Dear Name”, for example “Dear John”, and maintain an informal tone. It may seem illogical, but this is a requirement.",
  "Tasks, starting with “Dear Sir or Madam”  indicate a formal letter. Typically, such prompts align with the topic of the assignment and correspond to individuals or institutions whose names are unknown. Even if you suddenly come across a task where the letter is addressed to your boss, but the task begins with “Dear Sir or Madam”, and you understand that in real life you more likely know what their name is, still you are obliged to start and write in appropriate tone such as in the task.",
  "The trickiest type is the semi-formal, beginning with “Dear Mr…” (sometimes the surname is written for you). Address the recipient by their surname after “Mr./Ms” and maintain the suitable format. The confusing factor is when the task prompts you to write to a local mayor, for example, but starts with “Dear Mr…”. Despite knowing that using formal style and appealing “Dear Sir or Madam” in this case would be more appropriate, since you do not know the surname and have not met this person before, you should follow the task instructions and maintain a semi-formal tone. The same thing with informal letters. You may read and understand that it more suitable for friendly and informal type, but task “Dear Mr. …” requires writing only a semi-formal letter with corresponding greeting.",
];

export default function LetterTypes() {
  const renderTypesOfLetter = () => {
    return (
      <ul>
        {typesOfLetter.map((concept, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {concept}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="letter_types" className="article">
      <SEO
        title="How to accurately identify the type of letter"
        description="Discover how to identify letter types in the IELTS writing exam, ensuring your letters match assessment criteria for optimal scores"
      />
      <h1>How to accurately identify the type of letter?</h1>
      <div className="article-body">
        <div>
          It is widely acknowledged that there are three types of letters in the IELTS writing exam, such as formal,
          semi-formal, and informal letters. Moreover, it is crucial to align your letter type with the assessment
          criteria, as it has an impact on your score. However, determining the required type for your letter may not be
          straightforward. So how can you be sure that you are selecting the appropriate one?
        </div>
        <div>
          While identifying the type of letter based on the task can sometimes be elementary, it is not always as simple
          as it seems. Many students mistakenly assume that writing to a friend, neighbour, or family member requires an
          informal type; addressing an authority figure or lodging a complaint means a formal letter; and communicating
          with acquaintances like manager or co-workers falls into the semi-formal type. Nevertheless, this is a common
          misconception.
        </div>
        <div>
          Understanding the appropriate format, tone, and content for a letter is undoubtedly important. However, the
          initial step is to correctly identify the type of letter. In this case there is a valuable and tricky hint
          provided in the assessment itself. Following the prompt “Begin your letter as follows” you can see this guide.
          This instruction serves a purpose - sometimes to convince you that you are in the right direction, and other
          times to confuse you even more. Regardless, it is essential to adhere to this directive and stay true to it.
        </div>
        <div>The trick lies in the prompt provided at the end of the task:</div>
        {renderTypesOfLetter()}
        <div>Following this approach will help you quickly determine the task requirements and letter type.</div>
        <div>
          More examples of how to write
          <Link to="/articles/formal-letter" className="link">
            formal,
          </Link>
          <Link to="/articles/semiformal-letter" className="link">
            semi-formal,
          </Link>
          or
          <Link to="/articles/informal-letter" className="link">
            informal
          </Link>
          letters may be found in the following articles.
        </div>
      </div>
    </article>
  );
}
