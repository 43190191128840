import React from "react";

import "./notification.scss";

export default function Notification() {
  const toggle = e => {
    const div = document.getElementById("notification");
    div.classList.remove("open-animation");
    div.classList.add("close-animation");
  };

  return (
    <div id="notification" onClick={toggle} data-testid="notification">
      <div className="content">
        <div id="title"></div>
        <div id="text"></div>
      </div>
    </div>
  );
}
