import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import GeneralButton from "../../components/generalButton/generalButton";
import StartWritingButtons from "../../components/startWritingButtons/startWritingButtons";
import { config } from "../../config";
import bullet from "../../images/bullet_point.png";
import { getSafeIdHeight, getSafeQueryHeight } from "../../utils";

import "./homePage.scss";

export default function HomePage() {
  const [contentHeight, setContentHeight] = useState(null);
  const navigate = useNavigate();
  const goToArticlesPage = () => {
    navigate(`/articles`);
  };

  const calculateHeight = () => {
    const documentHeight = window.innerHeight || 0;

    const contentHeight =
      // height of header
      getSafeIdHeight("header_container") +
      // height of footer
      getSafeIdHeight("footer") +
      // height of dividers
      getSafeQueryHeight(".gradient-divider");

    setContentHeight(documentHeight - contentHeight);
  };

  useEffect(() => {
    if (window.innerWidth > config.MOBILE_WIDTH) {
      calculateHeight();
    }
  }, []);

  return (
    <div id="home_page" style={{ height: `${contentHeight}px` }}>
      <div className="main-content">
        <h1 className="main-header">Everything essential for your success in one tool</h1>

        <div className="main-text">
          <div>
            <img src={bullet} alt="Bullet-point" className="bullet-point" />
            <p>Are you currently in the process of preparing for the IELTS examination?</p>
          </div>
          <div>
            <img src={bullet} alt="Bullet-point" className="bullet-point" />
            <p>
              Do you consistently search for a suitable topic for an essay or ideas for a letter whenever you aim to
              enhance your writing skills?
            </p>
          </div>
        </div>

        <p className="secondary-header">Look no further, you are in the right place!</p>

        <p className="secondary-text">
          The <mark className="highlight">Writing Simulator</mark> is specifically designed to assist you in real-time
          writing practice, closely replicating the conditions of the actual exam
          <Link to="/info" className="learn-more-link">
            Learn more
          </Link>
        </p>
        <div className="article-link">
          <p>To unlock essential materials and tips for mastering IELTS writing</p>
          <GeneralButton text="CLICK HERE" action={goToArticlesPage} isActive />
        </div>
      </div>

      <div className="action-content">
        <div className="main-img"></div>

        <StartWritingButtons />
      </div>
    </div>
  );
}
