import React from "react";

import "./generalButton.scss";

export default function GeneralButton({ isActive, action, text, className }) {
  const getButtonClasses = () => {
    const buttonClasses = ["general-button"];
    if (className) {
      buttonClasses.push(className);
    }

    if (isActive) {
      buttonClasses.push("in-action");
    }

    return buttonClasses.join(" ");
  };

  return (
    <button className={getButtonClasses()} onClick={action}>
      {text}
    </button>
  );
}
