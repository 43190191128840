import React from "react";
import { Link } from "react-router-dom";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";
import letter_1 from "../../../images/formal_letter_1.png";
import letter_2 from "../../../images/formal_letter_2.png";

const recommendations = [
  "Appropriate greeting: Always begin formal letters with “Dear Sir or Madam”; do not include any names or surnames.",
  "Introduction: One or two sentences explaining the reason for your writing.",
  "Body paragraphs: Three paragraphs, each addressing one of the bullet points from the task.",
  "Closing remarks: Two to three sentences for closing remarks.",
  "Appropriate signing: For formal letters use “Yours faithfully”.",
];

export default function FormalLetter() {
  const renderRecommendations = () => {
    return (
      <ul>
        {recommendations.map((rec, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {rec}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="formal_letter" className="article">
      <SEO
        title="Is a formal letter so intimidating?"
        description="Learn how to identify and structure a formal letter for the IELTS exam, using sophisticated vocabulary and a highly formal tone to effectively express your understanding of the task."
      />
      <h1>Is a formal letter so intimidating?</h1>
      <div className="article-body">
        <div>
          The answer is definitely NO. On the contrary, many believe that this type of letter is the easiest one since
          there are no hidden challenges. To learn how to identify the type of letter from the task
          <Link to="/articles/letter-types" className="link">
            read here
          </Link>
          and the steps you should follow you can find
          <Link to="/articles/letter-basic-concepts" className="link">
            in this article.
          </Link>
        </div>
        <div>
          Your main task is to demonstrate an understanding of the questions and express it in the most sophisticated
          vocabulary. Try to keep a highly formal tone, articulate your ideas using the most advanced language, variety
          of grammatical structures, and complex expressions.
        </div>
        <div>
          Let's go through the structure of a formal letter. As with all letters, a formal letter should include:
        </div>
        {renderRecommendations()}
        <div>
          You may see examples below. These are not templates or perfect examples of formal letter writing, but they
          will give you an idea of what it looks like.
        </div>
        <img className="example" src={letter_1} alt="Formal letter" />
        <img className="example" src={letter_2} alt="Formal letter" />
        <div>
          Please note that the structure in both letters is clear, with visually identical paragraphs and spacing
          between them. The topics and bullet points are covered according to the task requirements.
        </div>
        <div>You may find additional useful materials in the articles below.</div>
      </div>
    </article>
  );
}
