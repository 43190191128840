let lastNotificationTimeout;

export const getSafeIdHeight = id => {
  return document.getElementById(id)?.offsetHeight || 0;
};

export const getSafeQueryHeight = query => {
  return document.querySelector(query)?.offsetHeight || 0;
};

export const countWords = input => {
  if (input !== "") {
    return input
      .replace(/(\r\n|\n|\r)/gm, " ")
      .split(" ")
      .filter(Boolean).length;
  }

  return 0;
};

export function showNotification(message, type, title = "", timeToRemove = 3) {
  if (!type) return;

  const divName = "notification";
  const div = document.getElementById(divName);

  div.classList.add(type);
  div.classList.remove("close-animation");
  div.classList.add("open-animation");

  document.getElementById("title").innerHTML = title;
  document.getElementById("text").innerHTML = message;

  if (lastNotificationTimeout) clearTimeout(lastNotificationTimeout);
  lastNotificationTimeout = setTimeout(() => {
    div.classList.remove("open-animation");
    div.classList.add("close-animation");
    lastNotificationTimeout = null;

    lastNotificationTimeout = setTimeout(() => {
      div.classList.remove(type);
      lastNotificationTimeout = null;
    }, 500);
  }, timeToRemove * 2000);
}
