import React from "react";
import { Link, useNavigate } from "react-router-dom";

import GeneralButton from "../generalButton/generalButton";

import "./startWritingModal.scss";

export default function StartWritingModal({ displayModal, closeModal, type }) {
  const navigate = useNavigate();
  const divStyle = {
    display: displayModal ? "block" : "none",
  };

  const goToWritingPage = () => {
    navigate(`/${type}/letter`);
  };

  return (
    <div className="general-modal" style={divStyle}>
      <div id="start_writing_modal" className="basic-modal">
        <div className="modal-header">Before you start</div>
        <div className="modal-content">
          <p>You have 60 minutes to finish the writing task, and once you start, there's no pausing or redoing.</p>
          <p>The simulator tries to re-create the exam conditions as closely as possible.</p>
          <p>If you are ready to start please push the Continue button to begin.</p>
          <p className="more-info">
            To learn more about how the Writing simulator works,
            <Link to="/info" className="info-link">
              click here
            </Link>
          </p>
        </div>
        <div className="action-buttons">
          <GeneralButton className="primary" action={goToWritingPage} text="Continue" />
          <GeneralButton isActive action={closeModal} text="Close" />
        </div>
      </div>
    </div>
  );
}
