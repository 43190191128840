import React from "react";
import { Route, Routes } from "react-router-dom";

import ArticleLayout from "./articleLayout";
import Layout from "./layout";
import AboutUs from "./pages/aboutUsPage/aboutUs";
import ArticlesPage from "./pages/articles/articlesPage/articlesPage";
import EssayBasicConcepts from "./pages/articles/essayBasicConcepts/essayBasicConcepts";
import EssayTypes from "./pages/articles/essayTypes/essayTypes";
import FormalLetter from "./pages/articles/formalLetter/formalLetter";
import InformalLetter from "./pages/articles/informalLetter/informalLetter";
import LetterBasicConcepts from "./pages/articles/letterBasicConcepts/letterBasicConcepts";
import LetterTypes from "./pages/articles/letterTypes/letterTypes";
import SemiFormalLetter from "./pages/articles/semiformalLetter/semiformalLetter";
import HomePage from "./pages/homePage/homePage";
import InfoPage from "./pages/infoPage/infoPage";
import Page404 from "./pages/page404/page404";
import Results from "./pages/results/results";
import WritingPage from "./pages/writingPage/writingPage";
import "./styles/general.scss";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/info" element={<InfoPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/articles" element={<ArticlesPage />} />
        <Route path="/:type/:task" element={<WritingPage />} />
        <Route path="/results" element={<Results />} />
        <Route path="*" element={<Page404 />} />
      </Route>

      <Route element={<ArticleLayout />}>
        <Route path="/articles/essay-basic-concepts" element={<EssayBasicConcepts />} />
        <Route path="/articles/letter-basic-concepts" element={<LetterBasicConcepts />} />
        <Route path="/articles/essay-types" element={<EssayTypes />} />
        <Route path="/articles/letter-types" element={<LetterTypes />} />
        <Route path="/articles/formal-letter" element={<FormalLetter />} />
        <Route path="/articles/informal-letter" element={<InformalLetter />} />
        <Route path="/articles/semiformal-letter" element={<SemiFormalLetter />} />
      </Route>
    </Routes>
  );
}
