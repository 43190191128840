import React from "react";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";

import "./essayTypes.scss";

const opinionQuestions = [
  "What is your opinion about this?",
  "Express your own opinion.",
  "To what extent do you agree with this statement?",
  "To what extent do you agree or disagree?",
  "Do you agree or disagree?",
];
const twoPointsQuestions = ["Discuss both views and give your own opinion.", "Discuss both views."];
const prosConsQuestions = [
  "Discuss the advantages and disadvantages.",
  "What are the advantages and disadvantages?",
  "Do the disadvantages outweigh the advantages?",
  "Discuss the advantages and disadvantages and give your own opinion.",
];
const problemSolutionQuestions = [
  "What are the causes and consequences of this problem?",
  "Explain the main causes and effects of this problem, and suggest some possible solutions.",
  "What are the primary causes of this problem? What solutions can you suggest?",
  "Why is it happening? What can be done to solve this problem?",
];
const twoTypeQuestions = [
  "Do you agree or disagree with this statement? What can be done to solve this problem?",
  "Do you agree or disagree with this statement? Would this cause any problems?",
  "What are the reasons for this development? What are the advantages and disadvantages?",
];

export default function EssayTypes() {
  const renderQuestions = questions => {
    return (
      <ul>
        {questions.map((question, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {question}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="essay_types" className="article">
      <SEO
        title="Does the essay type affect its content and structure?"
        description="Discover how to identify essay types in the IELTS writing exam, ensuring your writing structure match task criteria"
      />
      <h1>Does the essay type affect its content and structure?</h1>
      <div className="article-body">
        <div>
          It is widely recognized that there is no universally accepted approach to writing an essay for an IELTS exam.
          Presently, numerous study materials and guides offer contradicting advice. However, we recommend adhering to
          the following guidelines and structure to avoid inconsistency and misunderstanding across different essay
          types.
        </div>
        <div>
          So, what types of essays exist? Firstly, it is important to acknowledge that every essay represents an
          expression of opinion, whether it discusses an opinion or a factual statement, the response is formulated
          based on personal understanding. Nevertheless, the essays can be categorized into subtypes, namely:
        </div>
        <div className="type-container">
          <div>
            1. Opinion single point of view essay. You can identify this type when the topic includes such questions as:
          </div>
          {renderQuestions(opinionQuestions)}
          <div>
            This type of essay is considered the most obvious since the expectations are clear. In this case, it is
            necessary to present your opinion, supporting it with strong and significant factors. At the same time, do
            not forget about the paragraph structure of your writing.
          </div>
          <div>
            It is best to choose two compelling arguments why you think one way or another. Dedicate a paragraph to each
            of them, assisting them with an introduction, supporting points, an example, and a concluding statement.
          </div>
        </div>
        <div className="type-container">
          <div>
            2. Essays with two points of view; usually opposing views on the given problem need both to be analysed.
            Easy to recognize by phrases:
          </div>
          {renderQuestions(twoPointsQuestions)}
          <div>
            This essay type is closely similar to the previous one, the only thing is that it is simpler. Beforehand,
            you need to come up with two supporting arguments on the same topic that sometimes may be challenging if you
            run out of ideas. In this case, the structure is provided within the task itself. Each paragraph will be
            devoted to one viewpoint. The only thing is that the opposing opinion needs to be weakened at the end of the
            first body paragraph, transitioning seamlessly to the second paragraph where the advocated viewpoint is
            presented.
          </div>
          <div>
            A common question arises: What if I find both opinions valid, or conversely, consider them both incorrect?
            Should I express this in my essay? As was highlighted earlier in this article, there are no clear rules for
            writing an essay. If you are a remarkable and fast writer, confident in your knowledge, why not explore this
            approach? However, in other cases, sticking to a structured strategy is advisable. This approach reduces
            stress during the exam, allowing more time for writing a high-quality essay.
          </div>
        </div>
        <div className="type-container">
          <div>3. Advantages and disadvantages essays:</div>
          {renderQuestions(prosConsQuestions)}
          <div>
            This essay type, despite presenting a fact as the topic rather than an opinion, essentially requires the
            same approach as the ‘both views’ essays. Similar to the previous type, one body paragraph addresses the
            positive aspects while the other is devoted to the negatives. It is crucial to emphasize in the conclusion
            which factors are greater and what outweighs, as specified in the task.
          </div>
        </div>
        <div className="type-container">
          <div>4. Double-questioned essays that may be tricky. They are divided into two subtypes:</div>
          <div>- Problem and solution essay:</div>
          {renderQuestions(problemSolutionQuestions)}
          <div>
            In a problem and solution essay, you need to indicate the causes of an issue and propose solutions. Each
            question posed must be addressed distinctly. Therefore, the first body paragraph should outline the reasons
            behind the problem, while the second should offer potential solutions.
          </div>
          <div>
            It is important to note that if the task specifies reasons and solutions, there should be at least two of
            each. But under no circumstances presenting them as a list. Instead, describe and explain two reasons in the
            first body paragraph, followed by suggestions for resolving them in the second.
          </div>
          <div>- Two separate questions:</div>
          {renderQuestions(twoTypeQuestions)}
          <div>
            This type of essay is the most complex because it combines the types of essays listed above. At first
            glance, it seems that you need to devote three paragraphs to answering the task. However, the first half of
            the task should be covered in the first body paragraph, where you need to answer why you agree or not,
            express your opinion, and support it with an appropriate argument and conclusion; and the second half, with
            the reasons or the solution, following the task, should be devoted to the second body paragraph.
          </div>
        </div>
        <div>Despite all essays involving argumentation of your opinion, they vary in their approach to writing</div>
        <div>How to write appropriate introductions and conclusions will be observed closely in upcoming articles.</div>
      </div>
    </article>
  );
}
