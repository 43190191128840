import React from "react";

import GeneralButton from "../generalButton/generalButton";

import "./openResultPageModal.scss";

export default function OpenResultPageModal({ displayModal, closeModal, goToResultPage }) {
  const divStyle = {
    display: displayModal ? "block" : "none",
  };

  return (
    <div className="general-modal" style={divStyle}>
      <div id="go_to_result_page_modal" className="basic-modal">
        <div className="modal-content">
          <p>Are you sure you want to finish your writing?</p>
          <p>By pressing Continue, you will be taken directly to the Results Page.</p>
        </div>
        <div className="action-buttons">
          <GeneralButton className="primary" action={goToResultPage} text="Continue" />
          <GeneralButton isActive action={closeModal} text="Close" />
        </div>
      </div>
    </div>
  );
}
