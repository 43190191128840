import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Link, Outlet, useNavigate } from "react-router-dom";

import ArticlesList from "./components/articlesList/articlesList";
import Footer from "./components/footer/footer";
import GeneralButton from "./components/generalButton/generalButton";
import MainMenu from "./components/mainMenu/mainMenu";
import Notification from "./components/notification/notification";
import StartWritingButtons from "./components/startWritingButtons/startWritingButtons";
import logo from "./images/logo.png";

export default function ArticleLayout() {
  const navigate = useNavigate();
  const goToArticlesPage = () => {
    navigate(`/articles`);
  };

  const getCurrentURL = () => {
    return window.location.pathname;
  };

  const currentURL = getCurrentURL();

  return (
    <HelmetProvider>
      <div className="layout">
        <Notification />
        <div id="header_container">
          <Link id="logo" to="/">
            <img alt="Logo" src={logo} />
          </Link>

          <MainMenu />
        </div>

        <div className="gradient-divider"></div>

        <main>
          {<Outlet />}
          <div className="article-template">
            <StartWritingButtons />

            <GeneralButton text="Return to the articles list" action={goToArticlesPage} className="primary" />
            <ArticlesList currentURL={currentURL} />
          </div>
        </main>

        <Footer />
      </div>
    </HelmetProvider>
  );
}
