import React from "react";

import SEO from "../../../components/SEO/SEO";
import bullet from "../../../images/bullet_point.png";

const conceptsList = [
  "The assignment says to write at least 250 words, but what does this mean? Many people underestimate this requirement, assuming that writing 240 words is not critical. However, this is not the case. Anything below 250 words may result in a deduction of 0.5 points. Conversely, writing more words does not necessarily increase the likelihood of a higher score. Exceeding the word count may lead to more chances to make a mistake and in general, the examiner may consider you unable to express your thoughts concisely and clearly. Therefore, aim to write 270-280 words.",
  "Try to stick to a four-paragraph structure, no matter the type of essay: introduction, two paragraphs addressing the topic and task, and conclusion.",
  "Pay attention to the visual presentation. Each paragraph should be visible and divided by an empty line. However, avoid tabulation, it's unnecessary. Your essay should appear neat and cohesive at a glance, with an introduction and conclusion of two to three lines, and two body paragraphs, approximately equal length.",
  "Expand your vocabulary. Do not use very short and simple words where they can be replaced with more advanced ones. Also, strive to avoid repetition.",
  "Exercise caution with idioms. There are very few idioms available that can and are acceptable to use in an essay. If you don't know or are in doubt, better don't use them.",
  "Incorporate various grammatical structures, such as conditionals, modals, and relative clauses, etc.",
  "Demonstrate proficiency in using linking devices within your essay. Ideally, limit their use to five instances.",
  "Do not give examples from personal experience and avoid using pronouns such as “I”, “you”, and ”we”. The use of “I” is only acceptable in an introduction for an opinion essay, where you present your viewpoint and promise to analyze it further. Instead of “I would say that”, consider alternatives such as “It may be observed that”, “It is widely acknowledged that”, “There is a common belief that” etc.",
  "It is also important to note usage of interjections and contractions. They are only appropriate when writing informal letters, but definitely not for essays, so they must be avoided.",
  "When using modal words, avoid making sweeping generalizations. For instance, use “Government might take action” instead of “Government will take action,” as certainty cannot be guaranteed.",
  "In the introduction, accurately paraphrase the topic and present a clear opinion or statement.",
  "Ensure that the conclusion is logical and concise, without introducing new information or proposing future solutions.",
];

export default function EssayBasicConcepts() {
  const renderListOfConcepts = () => {
    return (
      <ul>
        {conceptsList.map((concept, idx) => {
          return (
            <li key={idx}>
              <img src={bullet} alt="Bullet-point" className="bullet-point" />
              {concept}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <article id="essay_basic_concept" className="article">
      <SEO
        title="Some aspects you might not know when writing an IELTS essay"
        description="Essential tips for writing effective essays in the IELTS exam"
      />
      <h1>Some aspects you might not know when writing an IELTS essay</h1>
      <div className="article-body">
        <div>
          There are numerous recommendations and guidelines for writing essays for the IELTS exam. Do not confuse the
          exam essay with the one written in high school. While it may appear simple, it can also be quite complex and
          confusing. However, there is no need to worry: the following tips will help you navigate through it.
        </div>
        <div>
          Let's explore some fundamental, but not compulsory, recommendations and techniques that you may not be aware
          of:
        </div>
        {renderListOfConcepts()}
        <div>Following these guidelines might be quite helpful in improving your IELTS writing test score.</div>
      </div>
    </article>
  );
}
