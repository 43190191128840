import React from "react";
import { useNavigate } from "react-router-dom";

import GeneralButton from "../../components/generalButton/generalButton";

import "./page404.scss";

export default function Page404() {
  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate(`/`);
  };

  return (
    <div id="page_404">
      <h1>404</h1>
      <h2>The page you were looking for does not exist</h2>
      <GeneralButton text="Go Home" action={goToHomePage} />
    </div>
  );
}
